<template>
  <ul class="grid">
    <li v-for="(item, index) in items" :key="item.title">
      <app-divider v-if="index !== 0" class="my-2" />

      <app-expandable
        class="grid rounded px-6 transition"
        :default-expanded="index === 0"
        open-class="bg-gray-50"
        tag="section"
        @expand="onExpand(item)"
      >
        <template #trigger="{ open }">
          <header
            class="flex items-center justify-between gap-4 py-4 text-left"
          >
            <h3 class="text-lg font-semibold">
              {{ item.title }}
            </h3>

            <app-icon
              class="shrink-0 transition"
              :class="{ 'rotate-180': open }"
              icon="ph:caret-down"
            />
          </header>
        </template>

        <p class="prose pb-6" v-html="item.content" />
      </app-expandable>
    </li>
  </ul>
</template>

<script lang="ts" setup>
import type { FaqItem } from "~/business-areas/customer-success/customer-success.model";

defineProps<{
  items: FaqItem[];
}>();

const { $analytics } = useNuxtApp();
const onExpand = ({ title }: FaqItem) =>
  $analytics.track("faq_item_read", { title });
</script>
